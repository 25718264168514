import { useState } from "react";
import "./../css/Login.css"
import store from './../mobx/store';
import { observer } from "mobx-react";
import { ToastContainer, toast } from 'react-toastify';
import Services from "../Services";
import { SetLocalItem } from "../uti/uti";
import { useNavigate } from "react-router-dom";
import Image from "./Image";

const Login = observer( () => {

  // const [phone, setphone] = useState("+8801628491731")
  // const [password, setpassword] = useState("Server123#")
  const [phone, setphone] = useState("")
  const [password, setpassword] = useState("")


  const navigate = useNavigate()

  

  const onSubmit = async (e) => {

    e.preventDefault()

    // store.auth.token = "2342343"

    if(!phone){
      toast.error("Invalid phone number")
      return null
    }

    if(!password){
      toast.error("Invalid password")
      return null
    }

    const res = await Services.Auth.Login(phone, password)
    if(res && res?.user?.role == 'admin'){
      store.auth = res
      SetLocalItem('auth', res)
      navigate("/")
    }else{
      toast.error("Something went wrong")
    }
  }

  return (
    <div className="login-wrap-container">  
    
      <div className="login-container">

        <Image 
          fullSrc={require("./../images/logo.png")}
          style={{
            display:'flex',
            margin:'0 auto',
            height:50,
          }}
        />

        <div className="login-content">
          <h1 className="welcome-text">Welcome Back</h1>
          <form 
            className="login-form"
            onSubmit={onSubmit}
          >
            <input 
              type="text" 
              placeholder="Username" 
              className="input-field" 
              onChange={e => setphone(e.target.value)}
              value={phone}
            />
            <input 
              type="password" 
              placeholder="Password" 
              className="input-field" 
              onChange={e => setpassword(e.target.value)}
              value={password}
            />
            <button type="submit" className="login-button">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
})

export default Login;
