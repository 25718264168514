
function HeadNav({onPressSideBarBtn}) {
  return (

    <nav>
      <div className="sidebar-button" onClick={onPressSideBarBtn} >
        <i className="bx bx-menu sidebarBtn" />
        <span className="dashboard">Welcome to sports arena admin panel</span>
      </div>
      {/* <div className="search-box">
        <input type="text" placeholder="Search..." />
        <i className="bx bx-search" />
      </div>
      <div className="profile-details">
        <img src="images/profile.jpg" alt="" />
        <span className="admin_name">Prem Shahi</span>
        <i className="bx bx-chevron-down" />
      </div> */}
    </nav>
      

  );
}

export default HeadNav;
