import * as Auth from './auth'
import * as User from './user'
import * as Ground from './Ground'

const Services = {
    Auth,
    User,
    Ground,
}

export default Services