import { CallAuthPost } from "./lib";


export async function ApproveUser(userData, ){

    const res = await CallAuthPost('/user/approve-shop-status', {
        userId:userData?._id,
        shopApproved:!userData?.shopApproved
    })

    console.log(res)

    if(res?.success){
        return true
    }
    return false

}