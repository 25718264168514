
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {Row, Col, Image, ListGroupItem, ListGroup } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import { BASE_URL, ShowConfirm } from '../uti/uti';


function SearchUserModal({show, handleClose, onSelectUser}) {

  const [searchKey, setsearchKey] = useState(null)

  const [data, setdata] = useState([])

  useEffect(() => {

    LoadData()

  }, [searchKey])

  const LoadData = async () => {


    let url = `/users?search=${searchKey}`


    const res = await CallAuthGet(url)

    console.log(res)

    if(res){
      setdata(res.result)
    }

  }

  const OnSelect = (ele) => {
    handleClose(false)
    onSelectUser(ele)
  }

  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search and Select</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-2" >
          <Form.Control
            placeholder="Search user by name, phone or shop name"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            onChange={e => setsearchKey(e.target.value)}
          />
          <Button variant="outline-secondary" id="button-addon2">
            Search
          </Button>
        </InputGroup>
        <ListGroup className="" >
          {data.map(ele => 
            <ListGroupItem >
              <Row  >
                <Col xs={3} md={2}>
                  <Image style={{width:'100%'}} src={BASE_URL +"/"+ele.picture} rounded />
                </Col>
                <Col xs={9} md={8}>
                  <h6>Name: {ele.name} </h6>
                  <h6>Phone: {ele.phone}</h6>
                  <h6>User Type: <b>{ele.role}</b></h6>
                </Col>
                <Col xs={2} md={2}>
                  <Button onClick={() => OnSelect(ele)} size="sm" variant="primary">Select</Button>
                </Col>
              </Row> 
            </ListGroupItem>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
      

  );
}

export default SearchUserModal;
