
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL, GetCurrentMonthDates, GetISOStringsForDay, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Services from '../Services';
import InnerLayer from '../components/InnerLayer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from '../components/Image';
import { ListGroup, Table } from 'react-bootstrap';
import SingleGround from '../components/SingleGround';

const ManageGround = observer(() => {

  const params = useParams()

  const [subData, SetsubData] = useState({
    rules:[],
    amenities:[],
    includes:[],
    gallery:[]
  })
  
  const [monthDateList, SetmonthDateList] = useState(GetCurrentMonthDates())
  const [loading, Setloading] = useState(false)

  // console.log(monthDateList)

  useEffect(() => {

    if(params?.slug){
      LoadData()
    }

  }, [])

  const LoadData = async (page = 1) => {

    let url = `/grounds/${params?.slug}`

    console.log("url", url)

    const res = await CallAuthGet(url)

    console.log(res)

    if(res?.result){
      SetsubData(res?.result)
      LoadBookings(res?.result?._id)
    }

  }

  const LoadBookings = async (_id) => {

    let url = `/grounds/${params?.slug}`

    console.log("url", url)

    const res = await CallAuthPost('/grounds/book-history', {
      ground:subData?._id,
      startDate:monthDateList[0],
      endDate:monthDateList[monthDateList?.length - 1]

    })

    console.log(res)

    

  }

  

  const Submit = async (e) => {

    e?.preventDefault()

    if(!subData?.name){
      
      return null

    }

    Setloading(true)

    const res = CallAuthPost('/ground', subData)

    Setloading(false)


    if(res?.success){
      toast.success("Saved")
    }

  }

  const OnPickFile = async (groundId, file) => {

    const formData = new FormData()

    formData.append("groundId", groundId)
    formData.append("file", file)

    const res = await CallAuthPost("/ground/add-image", formData)

    if(res?.success){
      LoadData()
    }

    console.log("res", res)

  }

  console.log(GetISOStringsForDay())

  const soltList = GetISOStringsForDay()

  return (
    <InnerLayer>
      <div className='screenContainer' >
        <div className='mb-2' >
          <Stack direction="horizontal" gap={2} >
            <h4 className='mb-0' >Grounds</h4>
          </Stack>
        </div>

        <div className='infinity-single-item' >

          <SingleGround 
            ele={subData}
          />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                {[
                  "06:00 AM - 07:30 AM",
                  "07:30 AM - 09:30 AM",
                  "09:00 AM - 07:30 AM",
                  "10:30 AM - 09:30 AM",
                  "06:00 AM - 07:30 AM",
                  "07:30 AM - 09:30 AM",
                  "06:00 AM - 07:30 AM",
                  "07:30 AM - 09:30 AM",
                  "06:00 AM - 07:30 AM",
                  "07:30 AM - 09:30 AM",
                  "06:00 AM - 07:30 AM",
                  "07:30 AM - 09:30 AM",
                ].map(ele => 
                
                  <th>{ele}</th>
                  
                )}
              </tr>
            </thead>
            <tbody>
              {monthDateList?.map(ele => 
                <tr>
                  <td>{moment(ele).format("Do MMM YYYY")}</td>
                  {["06:00 AM", "07:30 AM"].map(slotEle => 
                  
                    <td>Avaliable</td>

                  )}
                </tr>
              )}
            </tbody>
          </Table>

        </div>
        
      </div>
    </InnerLayer>
  );
})

export default ManageGround;
