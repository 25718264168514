import {
    observable,
    action,
    computed,
    toJS,
    decorate,
    makeObservable
  } from 'mobx';
import _ from 'lodash';
import Axios from 'axios';
import { observer } from 'mobx-react';

class AppStore {

    auth = {
        user:{},
        token:null
    }

    constructor(){
        makeObservable(this, {
            auth:observable
        })
    }



    
}

// decorate(AppStore, {
//     auth : auth
// });

var Store = new AppStore();

export default Store;