
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Services from '../Services';
import InnerLayer from '../components/InnerLayer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from '../components/Image';
import SingleGround from '../components/SingleGround';

const Grounds = observer(() => {

  const navigate = useNavigate()

  let [searchParams, setSearchParams] = useSearchParams();

  const [data, setdata] = useState([])
  const [hasNext, sethasNext] = useState(false)
  const [currentPage, setcurrentPage] = useState(1)

  const [urlParam, seturlParam] = useState({
    search:searchParams.get('search'),
  })

  const UpdateurlParam = (data) => {
    setSearchParams(RemoveEmptyDataFromObj({...urlParam, ...data}))
    seturlParam(urlParam ? {...urlParam, ...data} : data)
  }

  useEffect(() => {

    LoadData()

  }, [])

  const LoadData = async (page = 1) => {

    const {search } = urlParam

    let url = `/grounds?page=${page}`

    console.log("url", url)

    if (search) url += `&search=${search}`

    const res = await CallAuthGet(url)

    console.log(res)

    if(res){
      setcurrentPage(page)
      setdata(page > 1 ? [...data, ...res.result] : res.result)
      sethasNext(res.hasNext)
    }

  }

  return (
    <InnerLayer>
      <div className='screenContainer' >
        <div className='mb-2' >
          <Stack direction="horizontal" gap={2} >
            <h4 className='mb-0' >Grounds</h4>
            
          </Stack>
        </div>
        <InfiniteScroll
            dataLength={data.length}
            next={() => hasNext ? LoadData(currentPage + 1) : null}
            hasMore={hasNext}
            loader={<h4>Loading...</h4>}
        >
          {data.map((ele, index) => 
            <SingleGround 
              ele={ele}
              key={index}
            />
          )}

        </InfiniteScroll>
      </div>
    </InnerLayer>
  );
})

export default Grounds;
