
import { observer } from 'mobx-react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const Settings = observer(() => {

  return (
    <div className='screenContainer' >
        Settinfs screen
    </div>

  );
})

export default Settings;
