import axios from "axios";
import { BASE_URL, GetLocalItem } from "../uti/uti";
import { toast } from "react-toastify";

export async function CallAuthPost(url, payload){

    const auth = await GetLocalItem('auth')

    try{

        const res = await axios.post(BASE_URL+url, payload, {
            headers:{
                Authorization:`Bearer ${auth?.token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        toast.error(res?.data?.message)
        
        return false

    }catch(e){
        toast.error("Something went wrong")
        return false
    }
    
}

export async function CallAuthPut(url, payload){

    const auth = await GetLocalItem('auth')

    try{

        const res = await axios.put(BASE_URL+url, payload, {
            headers:{
                Authorization:`Bearer ${auth?.token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        toast.error(res?.data?.message)
        
        return false

    }catch(e){
        toast.error("Something went wrong")
        return false
    }
    
}

export async function CallAuthDelete(url, payload){

    const auth = await GetLocalItem('auth')

    try{

        const res = await axios.delete(BASE_URL+url, {
            headers:{
                Authorization:`Bearer ${auth?.token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        toast.error(res?.data?.message)
        
        return false

    }catch(e){
        toast.error("Something went wrong")
        return false
    }
    
}



export async function CallAuthGet(url){

    const auth = await GetLocalItem('auth')

    try{

        const res = await axios.get(BASE_URL+url, {
            headers:{
                Authorization:`Bearer ${auth?.token}`
            }
        })

        console.log("res", res)

        return res?.data

        if(res?.data.success){
            return res?.data
        }

        toast.error(res?.data?.message)
        
        return false

    }catch(e){
        toast.error("Something went wrong")
        return false
    }
    
}
