
import { BASE_URL } from '../uti/uti';
import ImagePlaceholder from './../images/image.jpeg'

function Image({src, fullSrc, style}) {
  return (

    
    <img 
      src={fullSrc ? fullSrc : src ? BASE_URL +"/"+ src : ImagePlaceholder}
      style={style}
    />
      

  );
}

export default Image;
