
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Services from '../Services';
import InnerLayer from '../components/InnerLayer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from '../components/Image';

const Users = observer(() => {

  let [searchParams, setSearchParams] = useSearchParams();

  const [data, setdata] = useState([])
  const [hasNext, sethasNext] = useState(false)
  const [currentPage, setcurrentPage] = useState(1)

  const [urlParam, seturlParam] = useState({
    search:searchParams.get('search'),
  })

  const UpdateurlParam = (data) => {
    setSearchParams(RemoveEmptyDataFromObj({...urlParam, ...data}))
    seturlParam(urlParam ? {...urlParam, ...data} : data)
  }

  useEffect(() => {

    LoadData()

  }, [urlParam?.search])

  const LoadData = async (page = 1) => {

    const {search, shopApproved, role } = urlParam

    let url = `/users?page=${page}`

    console.log("url", url)

    if (search) url += `&search=${search}`

    const res = await CallAuthGet(url)

    console.log(res)

    if(res){
      setcurrentPage(page)
      setdata(page > 1 ? [...data, ...res.result] : res.result)
      sethasNext(res.hasNext)
    }

  }

  return (
    <InnerLayer>
      <div className='screenContainer' >
        <div className='mb-2' >
          <Stack direction="horizontal" gap={2} >
            <h4 className='mb-0' >Users</h4>
            <div style={{width:"50%"}} >
              <InputGroup className="" >
                <Form.Control
                  placeholder="Search by name or phone"
                  onChange={e => UpdateurlParam({search:e.target.value})}
                />
                <Button variant="outline-secondary" id="button-addon2">
                  Search
                </Button>
              </InputGroup>
            </div>
            {/* <Button variant="primary" onClick={() => setsubmitData({})} >All</Button> */}
          </Stack>
        </div>
        <InfiniteScroll
            dataLength={data.length}
            next={() => hasNext ? LoadData(currentPage + 1) : null}
            hasMore={hasNext}
            loader={<h4>Loading...</h4>}
        >
          {data.map((ele, index) => 
            <Stack 
              direction="horizontal" 
              gap={4} 
              key={index}
              className="infinity-single-item"
            >
                <Image 
                  src={ele.picture}
                  style={{maxWidth:150}}
                />
                <div>
                  <h6>Name: <b>{ele.name}</b></h6>
                  <h6>Phone: <b>{ele.phone}</b></h6>
                  <Link
                    to={`/bookings?page=1&bookedBy=${ele?._id}`}
                  >
                    <Button
                      style={{marginTop:10}}
                      size="sm"
                    >
                      See Bookings
                    </Button>
                  </Link>
                </div>

                
                
            </Stack>
          )}

        </InfiniteScroll>
      </div>
    </InnerLayer>
  );
})

export default Users;
