import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import

export const ENV_MODES = {
    production:"production",
    development:"development"
}

export const ENV_MODE = ENV_MODES.production

export const DEV_BASE_URL = 'http://localhost:3333'
export const PROD_BASE_URL = 'https://api.thesportsarena.xyz'

export const BASE_URL = ENV_MODE == ENV_MODES.production ? PROD_BASE_URL : DEV_BASE_URL

export const AllBookingStatus = ["pending", "confirmed", "completed", "userCancelled", 'adminCancelled']
export const AllPaymentStatus = ["pending", "advancePaid", "success", "cancel", "failed"]

export function SetLocalItem (key, value) {

    localStorage.setItem(key, JSON.stringify(value));

}

export async function GetLocalItem (key) {

    const item = localStorage.getItem(key)

    if(item){
        return JSON.parse(item)
    }

    return false


}

export async function RemoveLocalItem (key, value) {
    localStorage.removeItem(key)
}

export async function ShowConfirm(onConfirm, onCancel, title, message){
    confirmAlert({
        title: title || 'Confirm to submit',
        message: message || 'Are you sure to do this.',
        buttons: [
            {
                label: 'Yes',
                onClick: onConfirm
            },
            {
                label: 'No',
                onClick: onCancel
            }
        ]
    });
}

export function RemoveEmptyDataFromObj(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

export  function GetCurrentMonthDates() {
    const currentDate = moment();
    const numberOfDays = currentDate.daysInMonth();
    const dates = [];
  
    for (let i = 1; i <= numberOfDays; i++) {
      const date = currentDate.date(i);
      dates.push(date.toISOString());
    }
  
    return dates;
}


export function GetISOStringsForDay() {
    const dayStart = new Date(); // Current date and time
  dayStart.setHours(6, 0, 0, 0); // Set time to 6:00 AM

  const intervals = [];
  const intervalInMinutes = 90; // 1.5 hours in minutes

  const hoursInDay = 21 - 6; // From 6:00 AM to 3:00 AM next day
  const totalIntervals = hoursInDay * 60 / intervalInMinutes;

  for (let i = 0; i < totalIntervals; i++) {
    const newTime = new Date(dayStart.getTime() + i * intervalInMinutes * 60000);
    intervals.push(newTime.toISOString());
  }

  return intervals;
  }