
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import HeadNav from './HeadNav';
import Sidebar from './Sidebar';
import Store from './../mobx/store';
import {
  useNavigate,
} from "react-router-dom";
import { GetLocalItem } from '../uti/uti';


const InnerLayer = observer(({children}) => {

  const navigate = useNavigate()

  const [sideBarActive, SetsideBarActive] = useState(false)

  const LoadLocalAuth = async () => {

    const localAuth = await GetLocalItem("auth")

    console.log("localAuth", localAuth)

    if(localAuth){
      Store.auth = localAuth
    }else{
      navigate("/login")

    }

  }

  useEffect(() => {

    console.log("this is called onerlayer", Store?.auth?.token)

    LoadLocalAuth()

  }, [Store?.auth?.token])

  return (
    <>
      <Sidebar sideBarActive={sideBarActive} />
      <section className="home-section">
        <HeadNav onPressSideBarBtn={() => SetsideBarActive(!sideBarActive)} />
        <div className="home-content">
          {children}
        </div>
      </section>
    </>

  );
})

export default InnerLayer;
