import axios from "axios";
import { BASE_URL, RemoveLocalItem } from "../uti/uti";
import { toast } from "react-toastify";
import Store from "../mobx/store";



export async function Login(phone, password){

    try{

        const res = await axios.post(BASE_URL+'/auth/signin', {
            phone,
            password
        })

        if(res?.data?.success){
            return res.data
        }

        toast.error(res?.data?.message)

        return false


    }catch(e){

        toast.error("Something went wrong")

        return false


    }



}

export function Logout(){

    Store.auth = {
        user:{},
        token:""
    }

    RemoveLocalItem('auth')

}