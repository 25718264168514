import Services from "../Services";
import {  Link } from "react-router-dom";

function Sidebar({sideBarActive}) {

  return (

      <div className={`sidebar ${sideBarActive ? "active" : ""}`} >
        <div className="logo-details">
          <i className="bx bxl-c-plus-plus" />
          <span className="logo_name">Sports Arena</span>
        </div>
        <ul className="nav-links"  >
          <li>
            <Link to="/" className="active">
              <i className="bx bx-grid-alt" />
              <span className="links_name">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/grounds">
              <i className="bx bx-box" />
              <span className="links_name">Grounds</span>
            </Link>
          </li>
          <li>
            <Link to="/users">
              <i className="bx bx-box" />
              <span className="links_name">Users</span>
            </Link>
          </li>
          <li>
            <Link to="/bookings">
              <i className="bx bx-box" />
              <span className="links_name">Bookings</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/settings">
              <i className="bx bx-box" />
              <span className="links_name">Product</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-list-ul" />
              <span className="links_name">Order list</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-pie-chart-alt-2" />
              <span className="links_name">Analytics</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-coin-stack" />
              <span className="links_name">Stock</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-book-alt" />
              <span className="links_name">Total order</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-user" />
              <span className="links_name">Team</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-message" />
              <span className="links_name">Messages</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-heart" />
              <span className="links_name">Favrorites</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bx bx-cog" />
              <span className="links_name">Setting</span>
            </Link>
          </li> */}
          <li className="log_out">
            <Link onClick={Services.Auth.Logout}>
              <i className="bx bx-log-out" />
              <span className="links_name">Log out</span>
            </Link>
          </li>
        </ul>
      </div>
      

  );
}

export default Sidebar;
