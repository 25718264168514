
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Services from '../Services';
import InnerLayer from '../components/InnerLayer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from '../components/Image';
import { ListGroup } from 'react-bootstrap';

const AddUpdateGround = observer(() => {

  const params = useParams()

  const [subData, SetsubData] = useState({
    rules:[],
    amenities:[],
    includes:[]
  })
  const [addingRule, SetaddingRule] = useState("")
  const [addingAmi, SetaddingAmi] = useState("")
  const [addingIncludes, SetaddingIncludes] = useState("")

  const [loading, Setloading] = useState(true)

  useEffect(() => {

    if(params?.slug){
      LoadData()
    }

  }, [])

  const LoadData = async (page = 1) => {

    let url = `/grounds/${params?.slug}`

    console.log("url", url)

    const res = await CallAuthGet(url)

    console.log(res)

    if(res?.result){
      SetsubData(res?.result)
    }

  }

  const AddOther = (field, value) => {
    let newData = JSON.parse(JSON.stringify(subData))
    newData[field].push(value)
    SetsubData(newData)

    SetaddingRule("")
    SetaddingAmi("")
    SetaddingIncludes("")
  }

  const RemoveOther = (field, index) => {
    let newData = JSON.parse(JSON.stringify(subData))
    newData[field].splice(index, 1)
    SetsubData(newData)
  }

  const Submit = async (e) => {

    e?.preventDefault()

    if(!subData?.name){
      
      return null

    }

    Setloading(true)

    const res = CallAuthPost('/ground', subData)

    Setloading(false)


    if(res?.success){
      toast.success("Saved")
    }

  }

  const OnPickFile = async (groundId, file) => {

    const formData = new FormData()

    formData.append("groundId", groundId)
    formData.append("file", file)

    const res = await CallAuthPost("/ground/add-image", formData)

    if(res?.success){
      LoadData()
    }

    console.log("res", res)

  }

  return (
    <InnerLayer>
      <div className='screenContainer' >
        <div className='mb-2' >
          <Stack direction="horizontal" gap={2} >
            <h4 className='mb-0' >Grounds</h4>
          </Stack>
        </div>

        <div className='infinity-single-item' >

          {subData?._id ?

            <div className="mb-3"  >
              <h6>Ground Images</h6>
              <Stack direction="horizontal" gap={2} >
                {subData?.gallery?.map((ele, index) => 
                  <img 
                    index={index}
                    src={BASE_URL + "/" + ele}
                    style={{width:"100px", height:"100px", border:"1px solid #ddd", borderRadius:10, overflow:'hidden', marginRight:10}}
                  />  
                )}
              </Stack>
              <h4 className="mt-3" >
                  <Form.Group controlId="formFile" >
                    <h5>Add Image</h5>
                    <Form.Control 
                      type="file" 
                      onChange={e => OnPickFile(subData._id, e.target.files[0])}
                      size="sm"
                    />
                  </Form.Group>
                </h4>
              
            </div>

          : null }

          <Form onSubmit={Submit} >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                value={subData?.name}
                onChange={e => SetsubData({...subData, name:e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Slot Price</Form.Label>
              <Form.Control
                type="number"
                autoFocus
                value={subData?.slotPrice}
                onChange={e => SetsubData({...subData, slotPrice:e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Phone</Form.Label>
              <Form.Control
                type="phone"
                autoFocus
                value={subData?.contactPhone}
                onChange={e => SetsubData({...subData, contactPhone:e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                value={subData?.contactEmail}
                onChange={e => SetsubData({...subData, contactEmail:e.target.value})}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Overview</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                defaultValue={subData?.overView} 
                value={subData?.overView} 
                onChange={e => SetsubData({...subData, overView:e.target.value})}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Full Address</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                defaultValue={subData?.fullAddress} 
                value={subData?.fullAddress} 
                onChange={e => SetsubData({...subData, fullAddress:e.target.value})}
              />
            </Form.Group>

            <div>
              <h6>Rules</h6>
              <ListGroup className="mb-3" style={{maxWidth:400}} >
                {subData?.rules.map((ele, index) => 
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      {ele}
                    </div>
                    <Badge bg="danger" pill onClick={() => RemoveOther('rules', index)} >
                      X
                    </Badge>
                  </ListGroup.Item>
                )}
              </ListGroup>

              <InputGroup className="mb-3" style={{maxWidth:400}} >
                <Form.Control
                  placeholder="Enter Rule"
                  value={addingRule}
                  onChange={e => SetaddingRule(e.target.value)}
                  
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={() => AddOther('rules', addingRule)} >
                  Add
                </Button>
              </InputGroup>
            </div>

            <div className='mt-4' >
              <h6>Amenities</h6>
              <ListGroup className="mb-3" style={{maxWidth:400}} >
                {subData?.amenities.map((ele, index) => 
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      {ele}
                    </div>
                    <Badge bg="danger" pill onClick={() => RemoveOther('amenities', index)} >
                      X
                    </Badge>
                  </ListGroup.Item>
                )}
              </ListGroup>

              <InputGroup className="mb-3" style={{maxWidth:400}} >
                <Form.Control
                  placeholder="Enter Aminity"
                  value={addingAmi}
                  onChange={e => SetaddingAmi(e.target.value)}
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={() => AddOther('amenities', addingAmi)} >
                  Add
                </Button>
              </InputGroup>
            </div>

            <div className='mt-4' >
              <h6>includes</h6>
              <ListGroup className="mb-3" style={{maxWidth:400}} >
                {subData?.includes.map((ele, index) => 
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      {ele}
                    </div>
                    <Badge bg="danger" pill onClick={() => RemoveOther('includes', index)} >
                      X
                    </Badge>
                  </ListGroup.Item>
                )}
              </ListGroup>

              <InputGroup className="mb-3" style={{maxWidth:400}} >
                <Form.Control
                  placeholder="Enter Includings"
                  value={addingIncludes}
                  onChange={e => SetaddingIncludes(e.target.value)}
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={() => AddOther('includes', addingIncludes)} >
                  Add
                </Button>
              </InputGroup>
            </div>

            <Button onClick={Submit}  >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Form>

        </div>
        
      </div>
    </InnerLayer>
  );
})

export default AddUpdateGround;
