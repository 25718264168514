import { Button, Stack } from "react-bootstrap"
import Image from './Image';
import { useNavigate } from "react-router-dom";


function SingleGround({ele, hideRightPanel}){

    const navigate = useNavigate()

    return(
        <Stack 
            direction="horizontal" 
            gap={4} 
            className="infinity-single-item"
        >
            <Image 
                src={ele?.gallery[0]}
                style={{maxWidth:150}}
            />
            <div style={{display:'flex', flex:1}} >
                <div style={{display:'block'}} >
                <h6>Name: <b>{ele?.name}</b></h6>
                <h6>Price: <b>{ele?.slotPrice}</b></h6>
                </div>
                
            </div>
            {!hideRightPanel ?
            <div>
                <div>
                <Button 
                    size="sm"
                    onClick={() => navigate("/update-ground/"+ele.slug)}
                >
                    Update Ground
                </Button>
                </div>
                <div style={{marginTop:10}} >
                <Button 
                    size="sm"
                    onClick={() => navigate("/manage-ground/"+ele.slug)}
                >
                    Manage Ground
                </Button>
                </div>
            </div>
            : null }
        </Stack>
    )
}

export default SingleGround